import apiservice from "@/api/V2/services/api.service";

const activateLink = (uuid, amount) => {
  return apiservice.put(`/payments/activateLink`, {uuid: uuid, amount: amount})
};

const deactivateLink = () => {
  return apiservice.put(`/payments/deactivateLink`)
};

const getActiveLink = () => {
  return apiservice.get(`/payments/activeLink`)
};

const checkPaymentStatus = (paymentUuid) => {
  return apiservice.post(`/payments/${paymentUuid}/checkStatus`)
};

const getPaymentStatus = (paymentUuid) => {
  return apiservice.get(`/payments/${paymentUuid}/getStatus`)
};


export {
  activateLink,
  deactivateLink,
  getActiveLink,
  checkPaymentStatus,
  getPaymentStatus
}
