<template>
  <div class="deliveryItemsSent-items">
    <div class=" deliveryItemsSent-sum" style="width:100%" v-if="(shipment!=null)">
      <div class="deliveryItemsSent-sum-client-info">
        <p>Клиент: {{ dstFio }}</p>
        <p>Номер отправления: {{ shipment.number }}</p>
      </div>

      <shipment-choose-goods
        :shipment="shipment"
        @change="onChooseGoods"
      />


    </div>
  </div>
  <div style="padding-top: 20px" v-if="isRefuseReasonEnabled">
    <h2>Выберите причину отказа</h2>
    <el-select v-model="partialIssuanceReason" placeholder="Выбрать причину" class="mb-4 w-full" size="large">
      <el-option v-for="item in reasonsList"
                 :key="item.id"
                 :label="item.reason"
                 :value="item.id"
      />
    </el-select>
  </div>
  <div class="deliveryItemsSent-items" style="margin-top: 10px">
    <div class="deliveryItemsSent-item-container">
      <shipment-payment
        :shipment="shipment"
        :amount="sumToPay()"
        @dialogClose="paymentDialogClose"
        :visible="isPaymentVisible"
      />
      <el-button type="primary" :disabled="!isPaymentEnabled" @click="paymentOpen()">Оплатить</el-button>
      <el-button type="primary" :disabled="!isIssueEnabled" @click="onIssueClick()">Выдать</el-button>
      <el-button type="primary" v-if="checkFromAvitoEnabled" @click="onCheckIfIssuedClick()">Проверить выдачу в Авито
      </el-button>
    </div>


    <el-dialog v-model="modalSmsSendVisible" title="Подтверждение" width="375px">
      <span>Выберите способ подтверждения</span>
      <el-select class="mt-1" v-model="shipmentCodeType" placeholder="Выбрать">
        <el-option
          v-for="item in availableCodeTypes"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        />
      </el-select>

      <template #footer>
      <span class="dialog-footer">
        <el-button @click="modalSmsSendVisible=false">Отмена</el-button>
        <el-button :loading="sendCodeLoading" type="primary" @click="onConfirm(shipmentCodeType)"
                   :disabled="this.shipmentCodeType === -1">
          Отправить
        </el-button>
      </span>
      </template>
    </el-dialog>

    <modal-sms-confirm
      v-model="modalSmsConfirmVisible"
      :shipment="shipment"
      :text="confirmText"
      :refused-good-ids="refusedGoodIds"
      :reason="partialIssuanceReason"
      :shipmentCodeType="shipmentCodeType"
    />
  </div>
</template>

<style>
.deliveryItemsSent-sum-product-quantity-text-container .el-checkbox__label {
  display: none;
}
</style>

<script>
import {mapActions, mapGetters, mapState} from "vuex";
import {ElNotification} from "element-plus";
import ModalSmsConfirm from "@/components/give/ModalSmsConfirm";
import ShipmentChooseGoods from "@/components/give/ShipmentChooseGoods";
import {AVITO_SHOP_ID} from "@/constants";

import {getDstClientFio} from "@/store/helper";
import {getShipmentWithGoods, issuedWithPayment} from "@/api/shipments";
import ShipmentPayment from "@/components/give/ShipmentPayment";


export default {
  name: "GiveConfirmAll",
  components: {ShipmentPayment, ModalSmsConfirm, ShipmentChooseGoods},
  data() {
    return {
      paymentMethod: 'not-selected',
      modalSmsConfirmVisible: false,
      modalSmsSendVisible: false,
      confirmText: '',
      partialIssuanceReason: null,
      shipmentCodeType: -1,
      sendCodeLoading: false,
      availableCodeTypes: [{label: "Загружаем...", value: -1}],
      shipment: {},
      refusedGoodIds: [],
      paymentSum: 0,
      isPaymentVisible: false,
      selectedItems: []
    };
  },
  computed: {
    ...mapGetters({
      shipmentCodeTypes: 'apiV2ShipmentCodeTypeStore/all',
      defaultShipmentCode: 'apiV2ShipmentCodeTypeStore/default',
    }),
    ...mapState({
      reasonsList: state => state.refuse.reasonsList
    }),
    checkFromAvitoEnabled() {
      return (this.shipment && !this.shipment.is_np);// && this.shipment.dts_point_id === 419)
    },
    isRefuseReasonEnabled() {
      return (this.shipment && (this.shipment.has_partial_issuance && this.refusedGoodIds.length))
    },
    isPaymentEnabled() {
      return (this.shipment &&
        (this.shipment.is_np && (this.shipment.payed_sum < this.paymentSum)));
    },
    isIssueEnabled() {
      return (this.shipment &&
        (!this.shipment.has_partial_issuance || (this.refusedGoodIds.length == 0) || this.partialIssuanceReason)
        && (!this.shipment.is_np || (this.paymentSum == this.shipment.payed_sum)));
    },
    dstFio() {
      return getDstClientFio(this.shipment);
    },
  },
  created() {
    this.$store.dispatch('refuse/getReasons', 'REFUSAL_BY_RECEIVER');
    if (this.shipmentCodeTypes.length === 0) {
      this.fetchShipmentCodeTypes().then(() => {
        this.shipmentCodeType = -1;
      });
    }
  },
  mounted() {
    this.readShipmentWithGoods(this.$route.params.uuid)
  },
  methods: {
    ...mapActions({
      code: 'shipments/code',
      fetchShipmentCodeTypes: 'apiV2ShipmentCodeTypeStore/fetch',
    }),
    sumToPay() {
      if (!this.shipment) { return 0}
      return (this.paymentSum - this.shipment.payed_sum)
    },
    onIssueClick() {
      if (this.shipment.is_np) {
        // здесь возможно надо показать сначала выбор причины
        this.sendIssueRequest()
      } else {
        this.modalSmsSendVisible = true;
      }
    },
    onChangePaymentMethod(value) {
      this.paymentMethod = value;
    },
    readShipmentWithGoods(uuid) {
      getShipmentWithGoods(uuid).then((data) => {
        this.shipment = data.data
        this.paymentSum = this.shipment.price
        this.fetchAvailableCodeTypes(this.shipment.shop_id);
      });
    },
    onChooseGoods(value) {
      this.refusedGoodIds = value.refusedGoods
      this.paymentSum = value.sumPrice
    },
    async sendIssueRequest() {
      try {
        const request = {
          refused_items: this.refusedGoodIds,
          refusal_reason: this.partialIssuanceReason
        };

        await issuedWithPayment(this.shipment.uuid, request);

        ElNotification({
          title: 'Успешно',
          message: `Отправление №${this.shipment.number} выдано`,
          type: 'success',
        });
        this.$router.push({name: 'give-item', params: {id: this.shipment.id}});

      } catch ({message}) {
        ElNotification({
          title: 'Ошибка',
          message: message || 'Не удалось выдать отправление',
          type: 'error',
        });
      }
    },
    async onCheckIfIssuedClick() {
      try {
        await this.$store.dispatch('apiV2ShipmentStore/checkIfIssued', this.shipment.uuid)
        ElNotification({
          title: 'Успешно',
          message: `Отправление №${this.shipment.number} выдано`,
          type: 'success',
        });

        this.$router.push({name: 'give-item', params: {id: this.shipment.id}});
      } catch ({message}) {
        ElNotification({
          title: 'Ошибка',
          message: message || 'Не удалось выдать отправление',
          type: 'error',
        });
      }
    }
    ,
    async onConfirm(shipmentCodeType) {
      this.sendCodeLoading = true;

      this.code({
        uuid: this.shipment.uuid,
        shipmentCodeType: shipmentCodeType,
      }).then(({data}) => {
        this.confirmText = data.confirm_text;
        this.modalSmsConfirmVisible = true;
      }).finally(() => {
        this.sendCodeLoading = false;
      });
    },
    async fetchAvailableCodeTypes(shop_id) {
      try {
        await this.$store.dispatch("shop/getAvailableCodeTypes", {
          shop_id: shop_id !== null ? parseInt(shop_id) : AVITO_SHOP_ID
        })

        this.availableCodeTypes = this.$store.state.shop.types;

        if (this.availableCodeTypes.length > 0) {
          this.shipmentCodeType = this.availableCodeTypes[0].value;
        }
      } catch (error) {
        console.error('Error fetching available code types:', error);
      }
    },
    paymentOpen() {
      this.isPaymentVisible = true
    },
    paymentDialogClose(value) {

      if (value) {

        this.paymentSuccess()
      }
      this.isPaymentVisible = false
    },
    paymentSuccess() {
      getShipmentWithGoods(this.shipment.uuid).then((data) => {
        this.shipment.payed_sum = data.data.payed_sum

      });
    },
  },
};
</script>
