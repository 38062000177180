export const NOTIFICATION_TIME_FORMAT = "HH:MM";
export const SHIPMENT_HISTORY_DATE_FORMAT = "dd.mm.yyyy, HH:MM";
export const DATE_FORMAT = "dd.mm.yyyy";
export const DATE_FORMAT_CREATE_FREIGHT = "yyyy-mm-dd";

export const BASE_API_ERROR = {
  unknown_error: "Неожиданная ошибка, попробуйте позднее.",
  server_error: "Ошибка сервера, попробуйте позднее.",
};

export const LOGIN_FORM_ERRORS = {
  ...BASE_API_ERROR,
  credentials_error:
    "Логин или пароль неверные, введите корректные логин и пароль и попробуйте еще раз.",
};

export const RESET_FORM_ERRORS = {
  ...BASE_API_ERROR,
  credentials_error:
    "Email не найден, введите правильный email и попробуйте еще раз.",
};

export const USERS_PAGE_LIMIT = 7;
export const SHIPMENTS_PAGE_LIMIT = 7;
export const PP_ACTS_PAGE_LIMIT = 7;


export const USER_STATUS_NON_ACTIVE = 0;
export const USER_STATUS_ACTIVE = 1;
export const USER_STATUS_BLOCKED = 2;

export const USER_STATUS_NAMES = [
  {value: USER_STATUS_NON_ACTIVE, title: "Неактивен"},
  {value: USER_STATUS_ACTIVE, title: "Активен"},
  {value: USER_STATUS_BLOCKED, title: "Заблокирован"}
]

export const USER_STATUS_VALUES = {
  0: 1,
  1: 0,
};

export const SHIPMENT_STATUS_VALUES = {
  0: "Импортирован",
  10: "Принят от отправителя",
  20: "Готов к отгрузке",
  30: "Зарегистрирована накладная",
  40: "Выдано курьеру",
  45: "В пути из ПВЗ",
  50: "Прибыл в РЦ",
  60: "Передано в транзит \"Город отправления\" - \"Город получения\"",
  70: "Поступил в РЦ город получения",
  75: "В пути на ПВЗ",
  80: "Готов к выдаче",
  90: "Выдано",
  100: "Отменен",
  110: "Возврат",
  140: "Не прибыл на ПВЗ (недостача)",
  150: "Прибыл на ПВЗ (излишек)",
  151: "Прибыл на ошибочный ПВЗ (Засыл)",
  160: "Перемещение на другой ПВЗ",
  91: "Выдано частично",
  200: "Утилизация",
  300: "Утеря",
  400: "Претензия",
};

export const FRANCHISE_SHIPMENT_STATUSES = {
  10: "Принят от отправителя",
  20: "Готов к отгрузке",
  40: "Выдано курьеру",
  75: "В пути на ПВЗ",
  80: "Готов к выдаче",
  90: "Выдано",
  151: "Прибыл на ошибочный ПВЗ (Засыл)",
};

export const SHIPMENT_IMPORTED = 0;
export const SHIPMENT_ACCEPTED_FROM_SENDER = 10;
export const SHIPMENT_READY_FOR_SHIPMENT = 20;
export const SHIPMENT_INVOICE_REGISTERED = 30;
export const SHIPMENT_GIVEN_TO_COURIER = 40;
export const SHIPMENT_ON_THE_WAY_FROM_PICKUP_POINT = 45;
export const SHIPMENT_ENTERED_AT_THE_RC = 50;
export const SHIPMENT_SENT_TO_TRANSIT_CD_CR = 60;
export const SHIPMENT_ENTERED_AT_THE_RC_2 = 70;
export const SHIPMENT_ON_THE_WAY_TO_THE_PICKUP_POINT = 75;
export const SHIPMENT_READY_TO_PICK_UP = 80;
export const SHIPMENT_ISSUED = 90;
export const SHIPMENT_ISSUED_PARTIALLY = 91;
export const SHIPMENT_ISSUED_WITH_ERROR = 92;
export const SHIPMENT_ISSUED_PARTIALLY_WITH_ERROR = 93;
export const SHIPMENT_CANCELLED = 100;
export const SHIPMENT_RETURN = 110;
export const SHIPMENT_DID_NOT_ARRIVE_AT_THE_PICKUP_POINT = 140;
export const SHIPMENT_ARRIVED_TO_PICK_UP_POINT_WRONG_TRANSPORT = 150;
export const SHIPMENT_ARRIVED_AT_WRONG_PICKUP_POINT = 151;
export const SHIPMENT_MOVING_TO_ANOTHER_POINT = 160;
export const SHIPMENT_DESTROYED_BY_AVITO_REQUEST = 200;
export const SHIPMENT_LOST_BY_CARRIER = 300;
export const SHIPMENT_CLAIM = 400;

export const FREIGHT_TYPES = {
  1: "Текущие",
  2: "Завершенные",
};

export const FREIGHT_TYPES_ADMIN = {
  1: "На ПВЗ",
  2: "Из ПВЗ",
};

export const FREIGHT_STATUS_VALUES = {
  ...SHIPMENT_STATUS_VALUES,
  0: "Создана",
  20: "Поступил на ПВЗ",
  101: "Принята",
  102: "Принята с расхождениями",
}

export const FREIGHT_STATUS_CREATED = 0;
export const FREIGHT_STATUS_ACCEPTED_ON_POINT = 20;
export const FREIGHT_STATUS_TAKEN_OK = 101;
export const FREIGHT_STATUS_TAKEN_ERRORS = 102;
export const FREIGHT_STATUS_CANCELLED = 200;

// const USER_ROLES = {
//   0: "super",
//   1: "admin",
//   2: "network_manager",
//   3: "financial_manager",
//   4: "work_manager",
//   5: "spvz_employee",
//   6: "franchisee",
//   7: "apvz_employee",
//   8: "territorial_manager",
// };

export const USER_ROLE_SUPER = 0;
export const USER_ROLE_ADMIN = 1;
export const USER_ROLE_NETWORK_MANAGER = 2;
export const USER_ROLE_FINANCIAL_MANAGER = 3;
export const USER_ROLE_WORK_MANAGER = 4;
export const USER_ROLE_SPVZ_EMPLOYEE = 5;
export const USER_ROLE_FRANCHISEE = 6;
export const USER_ROLE_APVZ_EMPLOYEE = 7;
export const USER_ROLE_TERRITORIAL_MANAGER = 8;
export const USER_ROLE_FRANCHISEE_MULTI = 9;
export const USER_ROLE_IM_OPERATOR = 10;
export const USER_ROLE_IM_ADMIN = 11;
export const UNVERIFIED_USER = 12;
export const USER_ROLE_SUPER_ADMIN = 15;

export const SENDING_STATUS_VALUES = {
  0: "Создана",
  10: "Наполняется",
  100: "Завершена",
  200: "Отменена"
}


export const SHIPMENT_TYPE_C2C = 10;
export const SHIPMENT_TYPE_B2C = 20;
export const SHIPMENT_TYPE_X_DELIVERY_FIRST_LEG = 30;
export const SHIPMENT_TYPE_X_DELIVERY_SECOND_LEG = 40;

export const POINT_STATUS_REPAIR = 'repair';
export const POINT_STATUS_OPEN = 'open';
export const POINT_STATUS_PENDING = 'pending';
export const POINT_STATUS_CLOSED = 'closed';
export const POINT_STATUS_RESERVED = 'reserved';
export const POINT_STATUS_ALMOST_OPEN = 'almost_open';

export const AVITO_SHOP_ID = 61;
export const CAINIAO_SHOP_ID = process.env.VUE_APP_CAINIAO_SHOP_ID;
export const REQUEST_STATUS_NEW = 1;
export const REQUEST_STATUS_CHECK = 2;
export const REQUEST_STATUS_APPROVED = 3;
export const REQUEST_STATUS_REJECT = 4;

export const INVENTORY_STATUS_PLANNED = 1;
export const INVENTORY_STATUS_IN_WORK = 2;
export const INVENTORY_STATUS_FINISHED = 3;
export const INVENTORY_STATUS_CANCELLED = 4;

export const BUILDING_TYPE_SHOPPING_CENTER = 0;
export const BUILDING_TYPE_BUSSINES_CENTER = 1;
export const BUILDING_TYPE_SUPERMARKET = 2;
export const BUILDING_TYPE_RESIDENTAL_BUILDING = 3;
export const BUILDING_TYPE_OTHER = 4;

export const BUILDING_TYPES = [
    {
        id: BUILDING_TYPE_SHOPPING_CENTER,
        name: "ТЦ"
    },
    {
        id: BUILDING_TYPE_BUSSINES_CENTER,
        name: "БЦ"
    },
    {
        id: BUILDING_TYPE_SUPERMARKET,
        name: "Супермаркет"
    },
    {
        id: BUILDING_TYPE_RESIDENTAL_BUILDING,
        name: "Жилой дом"
    },
    {
        id: BUILDING_TYPE_OTHER,
        name: "Другое"
    },
];

export const TAX_SYSTEM_UCN = 1;
export const TAX_SYSTEM_PSN = 2;
export const TAX_SYSTEM_OSN = 3;

export const TAX_SYSTEM_TYPES = [
    {
      id: TAX_SYSTEM_UCN,
      name: "УСН"
    },
    {
      id: TAX_SYSTEM_PSN,
      name: "ПСН"
    },
    {
      id: TAX_SYSTEM_OSN,
      name: "ОСН"
    },
]

export const RETURN_ACT_STATUS_IN_ASSEMBLY = 1;
export const RETURN_ACT_STATUS_READY_FOR_SHIPMENT = 2;
export const RETURN_ACT_STATUS_GIVEN_TO_COURIER = 3;

export const DB_CONNECTION_COLD = 'pgsql_cold';

export const PAYMENT_STATUS_CREATED = 0;
export const PAYMENT_STATUS_PAYED = 1;
export const PAYMENT_STATUS_CANCELLED = 2;
export const PAYMENT_STATUS_ERROR = 3;

export const GOOD_STATUS_CREATED = 10
export const GOOD_STATUS_ISSUED = 30
export const GOOD_STATUS_REFUSED = 40
export const GOOD_STATUS_LOST = 50
