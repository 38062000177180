export const DB_NAME = 'exmail_db';
export const STORAGE_UPDATES = 'updates';
export const STORAGE_POINTS = 'points';
export const STORAGE_CEILS = 'ceils';
export const SCHEDULE_SCHEMES = 'schedule_schemes';
export const DB_VERSION = 1;
let DB;

export default {
  async getDb() {
    return new Promise((resolve, reject) => {
      if (DB) {
        return resolve(DB)
      }
      const request = window.indexedDB.open(DB_NAME, DB_VERSION)
      request.onerror = e => {
        console.log('Error opening db', e)
        // eslint-disable-next-line prefer-promise-reject-errors
        reject('Error')
      }
      request.onsuccess = e => {
        DB = e.target.result
        resolve(DB)
      }
      request.onupgradeneeded = e => {
        let db = e.target.result
        db.createObjectStore(STORAGE_POINTS, {keyPath: 'id'})
        db.createObjectStore(STORAGE_CEILS, {keyPath: 'id'})
        db.createObjectStore(SCHEDULE_SCHEMES, {keyPath: 'id'})
        db.createObjectStore(STORAGE_UPDATES, {keyPath: 'storage_name'})
      }
    })
  },
  async getItems(storageName) {
    let db = await this.getDb()
    return new Promise(resolve => {
      let trans = db.transaction([storageName], 'readonly')
      trans.oncomplete = () => {
        resolve(items)
      }
      const store = trans.objectStore(storageName)
      const items = []
      store.openCursor().onsuccess = e => {
        const cursor = e.target.result
        if (cursor) {
          items.push(cursor.value)
          cursor.continue()
        }
      }
    })
  },
  async getItem(storageName, id) {
    let db = await this.getDb()
    return new Promise(resolve => {
      let trans = db.transaction([storageName], 'readonly')
      trans.oncomplete = () => {
        resolve(item.result)
      }
      const store = trans.objectStore(storageName)
      const item = store.get(id);
    });
  },
  async deleteItem(storageName, id) {
    const db = await this.getDb()
    return new Promise(resolve => {
      const trans = db.transaction([storageName], 'readwrite')
      trans.oncomplete = () => {
        resolve()
      }
      const store = trans.objectStore(storageName)
      store.delete(id)
    })
  },
  async clear(storageName) {
    const db = await this.getDb()
    return new Promise(resolve => {
      const trans = db.transaction([storageName], 'readwrite')
      trans.oncomplete = () => {
        resolve(true)
      }
      const store = trans.objectStore(storageName)
      store.clear()
    })
  },
  async saveItem(storageName, item) {
    let db = await this.getDb()
    let trans = db.transaction([storageName], 'readwrite')
    const store = trans.objectStore(storageName)
    store.put(item)
  },
  async saveItems(storageName, items) {
    let db = await this.getDb()
    return new Promise(resolve => {
      let trans = db.transaction([storageName], 'readwrite')

      trans.oncomplete = () => {
        resolve()
      }

      let store = trans.objectStore(storageName)
      items.forEach(data => {
        store.add(data);
      })

      this.saveItem(STORAGE_UPDATES, {
        'storage_name': storageName,
        'updated_at': new Date().getTime(),
      });
    })
  },
  async lastUpdateInterval(storageName) {
    return this.getItem(STORAGE_UPDATES, storageName).then((result) => {
      if (!result) {
        return Infinity;
      }

      return (new Date().getTime() - result.updated_at) / 1000 / 60;
    });
  }
}
