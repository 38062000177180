import apiV2Service from "@/api/V2/services/apiV2.service";

const store = {
    namespaced: true,
    actions: {
        registrationUser(context, params) {
            return apiV2Service.post('registration-user/registrationUser', params)
        },

        saveInfoUserLegalEntity(context, params) {
            return apiV2Service.post('registration-user/saveInfoUserLegalEntity', params)
        },

        getInfoUserLegalEntity(context, requestId) {
            return apiV2Service.get(`registration-user/getInfoUserLegalEntity/${requestId}`)
        },

        getPointRequest(context, requestId) {
          return apiV2Service.get(`registration-user/getPointRequest/${requestId}`)
        },

        sendRequestRegistrationToCheck(context, params) {
            return apiV2Service.post('registration-user/sendRequestRegistrationToCheck', params)
        },

        uploadPhotoPoint(context, params) {
            return apiV2Service.post('registration-user/uploadPhotoPoint', params)
        },

        deletePhotoPoint(context, params) {
            return apiV2Service.post('registration-user/deletePhotoPoint', params)
        },

        loadRequisitesLegalEntity(context, inn) {
            return apiV2Service.get(`registration-user/loadRequisitesLegalEntity/${inn}`);
        },

        getRequestsRegistrationUsers(context, params) {
            return apiV2Service.post('request-registration-user/getRequestsRegistrationUsers', params);
        },

        getRequestRegistrationById(context, requestId) {
            return apiV2Service.get(`request-registration-user/getRequestRegistrationById/${requestId}`);
        },

        getPointRequestById(context, requestId) {
            return apiV2Service.get(`request-registration-user/getPointRequestById/${requestId}`);
        },

        confirmRegistrationRequest(context, userId) {
            return apiV2Service.get(`request-registration-user/confirmRegistrationRequest/${userId}`);
        },

        confirmPointRegistrationRequest(context, userId) {
            return apiV2Service.get(`request-registration-user/confirmPointRegistrationRequest/${userId}`);
        },

        rejectRegistrationRequest(context, requestId) {
            return apiV2Service.get(`request-registration-user/rejectRegistrationRequest/${requestId}`);
        },

        rejectPointRegistrationRequest(context, requestId) {
          return apiV2Service.get(`request-registration-user/rejectPointRegistrationRequest/${requestId}`);
        },

        getDaDataSuggestions(context, address) {
            return apiV2Service.get(`registration-user/getDaDataSuggestions/${address}`);
        },

        askForInformation(context, params) {
            return apiV2Service.post('request-registration-user/askForInformation', params);
        },

        returnRequestFromReject(context, requestId) {
            return apiV2Service.get(`request-registration-user/returnRequestFromReject/${requestId}`);
        },

        returnPointRequestFromReject(context, requestId) {
          return apiV2Service.get(`request-registration-user/returnPointRequestFromReject/${requestId}`);
        },

        savePointForm(context, {requestId, data}) {
          return apiV2Service.post(`request-registration-user/updatePointRequest/${requestId}`, data);
        }
    },
};
export default store;
