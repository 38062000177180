import client from './client'
import createCrud from './createCrud'
import avito from "./ex-avito";

const {getAll, createItem, readItem, updateItem, deleteItem} = createCrud(client, '/shipments')

const getForFreight = async (filters) => {
  const query = Object.keys(filters).map(key => `${key}=${filters[key]}`)
  return await client.get(`/v2/shipments/getForFreight?${query.join('&')}`)
}
const surplus = async () => {
  return await client.get(`/shipments/surplus`)
};
const destroyed = async () => {
  return await client.get(`/shipments/destroyed`)
};
const issued = async (uuid, data) => {
  if (data.shipment_code_type === 2) {
    // Выполняем запрос через avito-microservice
    console.log('micro')
    return await avito.put(`/${uuid}/issued`, data);
  } else {
    //   Выполняем запрос через exbox
    console.log('exbox')
    return await client.put(`/shipments/${uuid}/issued`, data);
  }
};
const issuedWithPayment = async (uuid, data) => {
    return await client.put(`/shipments/${uuid}/issueWithPayment`, data);

};
const accept = async (id, data) => {
  return await client.put(`/shipments/${id}/accept`, data)
};
const placed = async (id, data) => {
  return await client.put(`/shipments/${id}/placed `, data)
};
const placedShipmentFromFreight = async (id, data) => {
  return await client.put(`/shipments/${id}/placedShipmentFromFreight `, data)
};
const code = async (uuid, shipmentCodeType) => {
  return await client.put(`/shipments/${uuid}/code`, { shipment_code_type: shipmentCodeType })
};

const getShipmentCode = async (id) => {
  return await client.get(`/shipments/get-id/${id}`);
};

const getPackageTypes = async () => {
  return await client.get('/package_types');
};

const getHistory = async (id) => {
  return await client.get(`/shipments/${id}/history`);
};

const cancelled = async (id, reasonId) => {
  return await client.put(`/shipments/${id}/cancelled`, { refusal_reason: reasonId } );
}

const cancelInsteadOfAccept = async (uuid, reasonId) => {
  return await client.put(`/shipments/${uuid}/cancelInsteadOfAccept`, { refusal_reason: reasonId } );
}

const readShipment = async (uuid) => {
  return await client.get(`/shipments/${uuid}/getByUuid` );
}
const getShipmentWithGoods = (uuid)=> {
  return  client.get(`/shipments/${uuid}/getWithGoods`)
}
const multiChangeStatus = async (data) => {
  return await client.post(`/shipments/multi-change-status`, data)
};

const recalculateFranchTariffs =  async (data) => {
  return await client.post('/shipments/recalculateFranchTariffs', data)
}

export {
  getAll,
  createItem,
  readItem,
  updateItem,
  deleteItem,
  surplus,
  destroyed,
  issued,
  issuedWithPayment,
  accept,
  placed,
  placedShipmentFromFreight,
  code,
  cancelled,
  getPackageTypes,
  getHistory,
  getShipmentCode,
  multiChangeStatus,
  getForFreight,
  cancelInsteadOfAccept,
  readShipment,
  recalculateFranchTariffs,
  getShipmentWithGoods
}
