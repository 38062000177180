<template>
  <k-dialog v-if="visibleDialog" :title="getTitle" @close="close" :width="500" :height="400">
    <figure class="text-center">

      <div class="h2">Сумма к оплате: {{ amount }} руб</div>

      <div style="height:30px">
      <Loader v-if="isLoaderVisible" :type="'pulsing'" :theme-color="'info'"/>
      </div>
      <div class="alert d-flex  alert-danger"
           role="alert" v-if="anotherPaymentNum">
        Сейчас ожидается оплата по заказу {{ anotherPaymentNum }} на сумму
        {{ anotherPaymentSum }} руб
      </div>
      <el-button type="danger" v-if="anotherPaymentNum" @click="deactivateCurrentLink()">Отменить эту оплату?
      </el-button>

      <div class="alert d-flex  alert-danger"
           role="alert" v-if="error">
        {{ error }}
      </div>

      <div class="alert alert-primary d-flex" v-if="isPaymentEnable"
           role="alert">
        Заказ можно оплачивать через QR-код
      </div>

      <div class="alert alert-success d-flex" v-if="isPaymentSuccessfull"
           role="alert">
        Оплата прошла успешно
      </div>
      <div class="alert alert-success d-flex" v-if="isPaymentUnsuccessfull"
           role="alert">
        Оплата не прошла
      </div>
    </figure>
    <dialog-actions-bar>
      <el-button type="primary" @click="checkPayment()" v-if="isPaymentEnable">Проверить статус оплаты
      </el-button>

    </dialog-actions-bar>
  </k-dialog>

</template>
<script>
import {Dialog, DialogActionsBar} from '@progress/kendo-vue-dialogs';
import {Loader} from "@progress/kendo-vue-indicators";
import '@progress/kendo-theme-default/dist/all.css';
import {activateLink, checkPaymentStatus, deactivateLink, getActiveLink, getPaymentStatus} from "@/api/payments";
import {
  PAYMENT_STATUS_CANCELLED,
  PAYMENT_STATUS_CREATED,
  PAYMENT_STATUS_ERROR,
  PAYMENT_STATUS_PAYED
} from "@/constants";

export default {
  name: "ShipmentPayment",
  components: {
    'k-dialog': Dialog,
    'dialog-actions-bar': DialogActionsBar,
    Loader,
  },
  props:
    ['shipment', 'amount', 'visible']
  ,
  watch: {
    visible: {
      handler(value, oldValue) {
        if (value !== oldValue) {
          this.init()
        }
      },
      deep: true
    },
  },
  data() {
    return {
      visibleDialog: false,
      isLoaderVisible: false,
      anotherPaymentNum: null,
      anotherPaymentSum: null,
      error: null,
      payment: null,
      timeoutId: null
    }
  },
  computed: {
    getTitle() {
      return 'Оплата заказа ' + this.shipment.number
    },
    isPaymentEnable() {
      return this.payment != null && (this.payment.status == PAYMENT_STATUS_CREATED)
    },
    isPaymentSuccessfull() {
      return (this.payment != null) && (this.payment.status == PAYMENT_STATUS_PAYED)
    },
    isPaymentUnsuccessfull() {
      return (this.payment != null) && ((this.payment.status == PAYMENT_STATUS_CANCELLED) || (this.payment.status == PAYMENT_STATUS_ERROR))
    },
  },
  emits: ['dialogClose'],
  methods: {
    init() {
      this.error = null

      this.anotherPaymentNum = null
      this.anotherPaymentSum = null
      this.visibleDialog = this.visible
      this.payment = null
      if (this.visible) {
        this.activatePayment()
      }
    },
    close() {
      this.stopCheck()
      this.visibleDialog = false
      const isPayed = this.isPaymentSuccessfull
      this.$emit('dialogClose', isPayed)
    },
    activatePayment() {
      this.isLoaderVisible = true
      activateLink(this.shipment.uuid, this.amount).then((data) => {
        this.payment = data.payment
        this.isLoaderVisible = false
        this.anotherPaymentSum = null
        this.anotherPaymentNum = null
      }).catch((err) => {
        this.isLoaderVisible = false

        // TODO прописать ошибку в константы
        if (err.status == 403) {
          this.getActivePayment()
        } else {
          this.error = err.message
        }
      })
    },
    getActivePayment() {
      this.isLoaderVisible = true
      getActiveLink().then((data) => {
        this.isLoaderVisible = false
        if (data.payment && (data.payment.amount == this.amount) && (data.payment.shipment_uuid == this.shipment.uuid)) {
          this.payment = data.payment
          this.startCheck()
        } else {
          this.anotherPaymentSum = data.payment.amount
          this.anotherPaymentNum = data.payment.shipment.number
        }
      }).catch((err) => {
        this.error = err.message
        this.isLoaderVisible = false
      });
    },
    deactivateCurrentLink() {
      this.isLoaderVisible = true
      deactivateLink().then(() => {
        this.stopCheck()
        this.isLoaderVisible = false
        this.activatePayment()
      }).catch((err) => {
        this.error = err.message
        this.isLoaderVisible = false
      });
    },
    checkPayment() {
      this.isLoaderVisible = true

      checkPaymentStatus(this.payment.uuid).then((data) => {
        this.isLoaderVisible = false
        this.payment = data.payment
      })
    },

    getPayment() {
      this.isLoaderVisible = true
      getPaymentStatus(this.payment.uuid).then((data) => {
        this.isLoaderVisible = false
        this.payment = data.payment
      })
    },
    startCheck() {
      let that = this
      this.timeoutId = setInterval(function () {
        that.getPayment();
      }, 20000);
    },
    stopCheck() {

      if (this.timeoutId) {
        clearTimeout(this.timeoutId)
        this.timeoutId = null
      }
    }
  },

}
</script>
<style>
.h1 {
  font-weight: 600;
  font-size: 150%;
  line-height: 44px;
}

.h2 {
  font-weight: 600;
  font-size: 150%;
  line-height: 44px;
  padding-bottom: 30px;
}
</style>
