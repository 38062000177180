<template>
  <div style="margin-top: 30px;">
    <div v-if="verified === '1' && text === ''" style="text-align: center">
      <el-icon :color="'#4ab34d'" :size="40">
        <component :is="SuccessFilled"/>
      </el-icon>
      <p style="font-size: 20px; padding: 10px 0">Вы успешно подтвердили email!</p>
      <router-link to="{{frontURL}}">
        Продолжить регистрацию!
      </router-link>
    </div>

    <div v-if="verified === '0' && text === 'emailAlreadyConfirmed'" style="text-align: center">
      <el-icon :color="'#e79c14'" :size="40">
        <component :is="WarningFilled"/>
      </el-icon>
      <p style="font-size: 20px; padding: 10px 0">Вы уже подтвердили email!</p>
    </div>
  </div>
</template>

<script>
import {SuccessFilled} from "@element-plus/icons-vue";
import {WarningFilled} from "@element-plus/icons-vue";

const frontURL = process.env.VUE_APP_API_ROOT;

export default {
  name: "RegistrationConfirmedEmail",
  data() {
    return {
      SuccessFilled,
      WarningFilled,
      verified: false,
      text: '',
      frontURL,
    }
  },
  created() {
    this.verified = this.$route.query.verified;
    this.text = this.$route.query.text ?? '';
  }
}
</script>

<style scoped>

</style>
