<template>
  <nav class="navbar">
    <div class="header-title">
      <a
          v-if="isHeaderBackLink"
          class="back-button"
          href="javascript:void(0)"
          @click.prevent="$router.go(-1)"
      >
        <img src="@/assets/img/back_icon.svg" alt="backIcon" />
      </a>
      <a
          class="navbar-brand"
          href="javascript:void(0)"
          @click.prevent="onTitleClick"
      >{{ title }}</a
      >
    </div>

    <div class="right-part-nav">
      <el-tooltip
          v-if="isAdmin()"
          class="box-item"
          effect="dark"
          content="Подключение для холодных данных"
          placement="bottom-start"
      >
        <el-switch v-model="coldConnectionEnabled" />
      </el-tooltip>
      <el-tooltip
          class="box-item"
          effect="dark"
          content="Обновить справочники"
          placement="bottom-start"
      >
        <el-button
            class="dict-reset-button"
            @click="resetDictionary"
            :icon="Refresh"
        ></el-button>
      </el-tooltip>
      <Profile :avatar="profile.avatar" />
    </div>
  </nav>
</template>

<script>
import Profile from "./Profile.vue";
import {ElNotification} from "element-plus";
import {Refresh} from '@element-plus/icons-vue'
import {USER_ROLE_ADMIN, USER_ROLE_SUPER} from "@/constants";
import {mapGetters} from "vuex";
import { markRaw } from "vue";

export default {
  name: "Header",
  data() {
    return {

      profile: {
        avatar: undefined,
      },
      Refresh: markRaw(Refresh),
      coldConnectionEnabled: false,
    };
  },
  watch: {
    coldConnectionEnabled(val) {
      const token = this.$store.state.user.token;
      localStorage.setItem(token + ".coldConnectionEnabled", val);
    }
  },
  mounted() {
    const token = this.$store.state.user.token;
    this.coldConnectionEnabled = JSON.parse(localStorage.getItem(token + ".coldConnectionEnabled"));
  },
  computed: {
    ...mapGetters({
      roleId: 'user/role_id',
    }),
    title() {
      return this.$store.state.title;
    },
    isHeaderBackLink() {
      return this.$route.meta.isHeaderBackLink;
    },
  },
  methods: {
    isAdmin() {
      return [USER_ROLE_ADMIN, USER_ROLE_SUPER].includes(this.roleId);
    },
    resetDictionary() {
      const pointsPromise = this.$store.dispatch('points/getList', {force: true});
      const ceilsPromise = this.$store.dispatch('ceils/getList', {force: true});

      Promise.all([pointsPromise, ceilsPromise]).then(() => {
        return ElNotification({
          title: 'Справочники успешно обновлены',
          message: 'Справочники загружены с сервера',
          type: 'success',
        });
      }, (error) => {
        return ElNotification({
          title: 'Ошибка при сбросе справочников',
          message: error,
          type: 'error',
        });
      });
    },
  },
  components: {

    Profile,

  },
};
</script>
<style scoped>
.dict-reset-button.el-button--default:hover {
  background: none;
  color: #556EE6;
}

.dict-reset-button {
  border: none;
  margin-left: 20px;
  margin-right: 20px;
}
</style>
