<template>
  <div class="grid-container-main" v-if="isAuthenticated && currentUser">
    <div class="sideBar">
      <Sidebar :user="currentUser"/>
    </div>
    <div class="header">
      <Header />
    </div>
    <div class="context_menu">
      <user-news-modal></user-news-modal>
      <acceptance-contract-modal></acceptance-contract-modal>
      <router-view></router-view>
    </div>
  </div>

  <div class="grid-container-main" v-else-if="isRegistrationRoute && !isAuthenticated">
    <BaseTemplateFrontWithoutAuth />
  </div>

  <div class="grid-container-main" v-else-if="isResetPasswordRoute && !isAuthenticated">
    <BaseTemplateFrontWithoutAuth />
  </div>

  <div class="Container" v-else>
    <div class="LeftPart">
      <img class="logo_prev" src="./assets/img/logo_icon.svg" alt="logo" />
    </div>
    <div class="RightPart">
      <LoginForm @getUser="currentUser" @forget="showForget" />
    </div>
    <Modal :show="showReset" @close="hideForget" modifyer="Forget">
      <ForgetForm />
    </Modal>
  </div>
  <Modal :show="!!addUser" modifyer="usercreate">
    <UserAdd
      @user:cancel="closeUserAdd"
      @user:change="onUserChange"
    />
  </Modal>
  <Loader></Loader>
</template>

<script>
import { useHead } from "@vueuse/head";

import Header from "src/components/header/Header.vue";
import Sidebar from "src/components/sidebar/Sidebar.vue";
import LoginForm from "src/components/userforms/LoginForm.vue";
import UserAdd from "src/components/userforms/UserAdd.vue";
import Modal from "src/components/modal/Modal.vue";
import ForgetForm from "src/components/userforms/ForgetForm.vue";
import { useScanner } from '@/composition/useScanner.js';
import Loader from '@/components/Loader.vue';
import AcceptanceContractModal from "@/components/acceptanceContract/acceptanceContractModal.vue";
import FrontendNeedUpdateService from "@/api/V2/services/FrontendNeedUpdateService";
import EventBus from "@/helpers/EventBus";
import BaseTemplateFrontWithoutAuth from "@/components/base/BaseTemplateFrontWithoutAuth";
import UserNewsModal from "@/components/userNews/UserNewsModal.vue";
import '@progress/kendo-theme-default/dist/all.css';


export default {
  name: "App",
  components: {
    BaseTemplateFrontWithoutAuth,
    UserNewsModal,
    AcceptanceContractModal,
    Header,
    Sidebar,
    LoginForm,
    ForgetForm,
    Modal,
    UserAdd,
    Loader,
  },
  setup() {
    useScanner(),

    useHead({
      link: [
        { rel: "preconnect", href: "https://fonts.googleapis.com" },
        { rel: "preconnect", href: "https://fonts.gstatic.com" },
        {
          rel: "stylesheet",
          href: "https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap",
        },
      ],
    });
  },
  async mounted() {
    FrontendNeedUpdateService.startCheck();

    if (this.$store.getters["user/isAuthenticated"]) {
      await this.$store.dispatch("user/getAuthUser");
    }

    EventBus.on("unauthorizedAccess", () => {
      this.$store.store.dispatch("user/logout");
    });
    EventBus.on("loading", () => {
      this.$store.dispatch('loading', true);
    });
    EventBus.on("loaded", () => {
      this.$store.dispatch('loading', false);
    });
  },
  computed: {
    isAuthenticated() {
      return this.$store.getters["user/isAuthenticated"];
    },
    isRegistrationRoute() {
      return this.$route.name === 'registration' || this.$route.name === 'RegistrationConfirmedEmail';
    },
    isResetPasswordRoute() {
      return this.$route.name === 'resetPassword';
    },
    showReset() {
      return this.$store.state.user.showReset;
    },
    addUser() {
      return this.$store.state.user.newUser;
    },
    currentUser() {
      return this.$store.state.user.user;
    },
  },
  methods: {
    onUserChange(patch) {
      this.$store.commit("user/UPDATE_USER", patch);
    },
    closeUserAdd() {
      this.$store.commit("user/USER_CREATED");
      this.$store.commit("user/SET_ERRORS", {
        key: "saveUser",
        val: {},
      });
    },
    showForget() {
      this.$store.commit("user/OPEN_RESET");
    },
    hideForget() {
      this.showForgetPopup = false;
    },
  },
};
</script>

<style lang="scss">
@import "~bootstrap/dist/css/bootstrap.min.css";
@import "~element-plus/dist/index.css";
@import "./assets/style/element/index.scss";
@import "./assets/style/main.css";
@import "./assets/style/suggestions.min.css";
@import "./assets/style/base.sass";
@import '~vue-select/dist/vue-select.css';

#app {
  height: 100%;
}
.grid-container-main {
  min-width: 1400px;
  height: 100%;
  overflow-x: auto;
  overflow-y: scroll;
}
.invalid-feedback {
  display: block;
}
.Container {
  min-width: 1400px;
}
</style>
